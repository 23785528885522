// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blank-js": () => import("./../src/pages/blank.js" /* webpackChunkName: "component---src-pages-blank-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-drop-in-js": () => import("./../src/pages/drop-in.js" /* webpackChunkName: "component---src-pages-drop-in-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investor-js": () => import("./../src/pages/investor.js" /* webpackChunkName: "component---src-pages-investor-js" */),
  "component---src-pages-our-companies-js": () => import("./../src/pages/our-companies.js" /* webpackChunkName: "component---src-pages-our-companies-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-startup-js": () => import("./../src/pages/startup.js" /* webpackChunkName: "component---src-pages-startup-js" */),
  "component---src-pages-startup-tools-js": () => import("./../src/pages/startup-tools.js" /* webpackChunkName: "component---src-pages-startup-tools-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

